import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import directives from "./directives/index";
import store from "./store";
import "./assets/css/common.scss";
import "ol/ol.css";
import "element-plus/dist/index.css";
import { TMapOptions } from 'ry-map';
TMapOptions.setKey = '918abe375dde75d1aed8085e1e652088';
const app = createApp(App);
app.use(store).use(directives).use(router).mount("#app");


