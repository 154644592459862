export default {
  install(Vue:any) {
    Vue.directive('lmap', {
      created: (el:HTMLElement, binding:any, vnode:any)  => {
        const that = vnode.context;
		const callback = binding.value;
		if(typeof callback  ===  'function'){
			binding.value(el);
		}
      },
    });
}
}